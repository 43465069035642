import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const ChristmasHomeSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: ${({ direction }) => (direction ? "#F3FBFE" : "#222F5C")};

  @media (max-width: 600px) {
    margin: 10px 10px 0px;
    width: calc(100% - 20px);
  }
`

const ChristmasContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  flex-direction: ${({ direction }) => (direction ? "row-reverse" : "row")};

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const TopText = styled.div`
  color: #262626;
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  margin: 0px 0px 7px 0px;
`

const LeftSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
`

const ChristmasTextWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: ${({ direction }) => (direction ? "#262626" : "#fff")};

  @media (max-width: 1068px) {
    padding: 20px 30px;
  }

  @media (max-width: 992px) {
    padding: 20px 20px;
  }

  @media (max-width: 600px) {
    padding: 25px 20px;
  }
`

const Title = styled.div`
  font-size: 30px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;

  @media (max-width: 850px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 20px 0px 25px;
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TextTitle = styled.div`
  color: #262626;
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: bold;
  margin: 0px 0px 10px 0px;

  @media (max-width: 380px) {
    font-size: 17px;
  }
`

const TextSubtitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  font-family: "Gotham Book";
  font-weight: 500;

  @media (max-width: 380px) {
    font-size: 13px;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: bold;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }

  strong {
    font-weight: 900;
  }
`

const BottomTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;

  @media (max-width: 600px) {
    margin-top: 25px;
  }
`

const BottomText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const MatelasBottomText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  margin-top: 20px;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const DesktopBannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileBannerImage = styled.img`
  width: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 1200px) {
      width: 110% !important;
      height: 110% !important;
    }

    @media (max-width: 992px) {
      width: 150% !important;
      height: 150% !important;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  iframe {
    width: 140% !important;
    height: 140% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 480px) {
      width: 130% !important;
      height: 130% !important;
    }

    @media (max-width: 420px) {
      width: 112% !important;
      height: 112% !important;
    }

    @media (max-width: 350px) {
      width: 130% !important;
      height: 130% !important;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const TopSection = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  color: #fff;
  min-height: 400px;

  @media (max-width: 1200px) {
    min-height: 350px;
  }

  @media (max-width: 850px) {
    min-height: 300px;
  }

  @media (max-width: 480px) {
    min-height: 240px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  background: ${({ direction }) => (direction ? "#262626" : "#fff")};
  padding: 10px 20px;
  color: ${({ direction }) => (direction ? "#fff" : "#262626")};
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  margin: 0px;
  border-radius: 25px 0px;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const MedicaleImage = styled.img`
  width: 100%;
  max-width: 90px;
  margin: 0px 20px 0px 0px;

  @media (max-width: 600px) {
    max-width: 70px;
    margin: 0px 10px 0px 0px;
  }
`

const GiftImage = styled.img`
  width: 100%;
  max-width: 25px;
  margin: 0px 10px 0px 0px;
`

const LoopImage = styled.img`
  width: 100%;
  max-width: 70px;
  margin: 0px 10px 0px 0px;
`

const ChristmasHomeSection = ({ intl, data, direction, section }) => {
  return (
    data && (
      <ChristmasHomeSectionWrapper direction={direction}>
        {data.button_text && data.button_url && (
          <AbsoluteButton to={data.button_url}>
            <ButtonTitle>{data.button_text}</ButtonTitle>
          </AbsoluteButton>
        )}
        <ChristmasContainer direction={direction}>
          <LeftSection>
            <ChristmasTextWrapper direction={direction}>
              {section === "lyneup" && (
                <TopText
                  dangerouslySetInnerHTML={{
                    __html: "Nouveau modèle",
                  }}
                />
              )}
              {data.title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              )}
              {data.text && (
                <TextWrapper>
                  {/* {section === "lyneup" && (
                    <MedicaleImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/241a9fdc-70da-4d11-b0c9-af44f8befbb0.png"
                      alt=""
                    />
                  )} */}
                  {section === "lyneup" && (
                    <MedicaleImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/88d9ae01-cf82-45ae-a2e2-5b30130215c7.jpg"
                      alt=""
                    />
                  )}
                  {section === "lyneup" ? (
                    <TextBox>
                      <TextTitle
                        dangerouslySetInnerHTML={{
                          __html: "Élu Produit de l’Année 2024",
                        }}
                      />
                      <TextSubtitle
                        dangerouslySetInnerHTML={{
                          __html:
                            "Co-créés avec 857 utilisateurs, les nouveaux modèles gardent le meilleur de la technologie tout en diversifiant les styles et optimisant votre confort. ",
                        }}
                      />
                    </TextBox>
                  ) : (
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: data.text,
                      }}
                    />
                  )}
                </TextWrapper>
              )}
              {data.button_text && data.button_url && (
                <ButtonWrapper>
                  <Button direction={direction} to={data.button_url}>
                    {data.button_text}
                  </Button>
                </ButtonWrapper>
              )}
              {data.bottom_text && (
                <BottomTextWrapper>
                  {section === "oreiller" ? (
                    <LoopImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/e7b341ca-8d1d-4ab2-adba-3998ec3bf04b.png"
                      alt=""
                    />
                  ) : (
                    <GiftImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/3396cdcb-dce5-4c22-bde3-43cebcd5c0bc.svg"
                      alt=""
                    />
                  )}
                  <BottomText
                    dangerouslySetInnerHTML={{
                      __html: data.bottom_text,
                    }}
                  />
                </BottomTextWrapper>
              )}
              {section === "matelas" && (
                <MatelasBottomText
                  dangerouslySetInnerHTML={{
                    __html:
                      "*D’après des études quantitatives menées en mars 2024 sur 175 utilisateurs et en mars 2023 sur 106 utilisateurs du matelas PERCKO",
                  }}
                />
              )}
            </ChristmasTextWrapper>
          </LeftSection>
          <RightSection>
            <TopSection>
              {data.image && data.mobile_image && (
                <DesktopBannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}

              {data.image && data.mobile_image && (
                <MobileBannerImage
                  className="lazyload"
                  data-src={data.mobile_image}
                  alt={data.alt ? data.alt : ""}
                />
              )}

              {data.video && data.mobile_video && (
                <DesktopVideoWrapper>
                  <ReactPlayer
                    url={data.video}
                    playing={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    width="100%"
                    height="100%"
                    playsinline={true}
                    playsInline
                  />
                </DesktopVideoWrapper>
              )}

              {data.video && data.mobile_video && (
                <MobileVideoWrapper>
                  <ReactPlayer
                    url={data.mobile_video}
                    playing={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    width="100%"
                    height="100%"
                    playsinline={true}
                    playsInline
                  />
                </MobileVideoWrapper>
              )}
            </TopSection>
          </RightSection>
        </ChristmasContainer>
      </ChristmasHomeSectionWrapper>
    )
  )
}

export default injectIntl(ChristmasHomeSection)
